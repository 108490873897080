<template>
    <div id="media-edit">
        <div class="table-holder">

            <form @submit.prevent="updateMedia()">
                <PageView :pageTitle="'Edit Media'" @on-save="() => { }" @on-cancel="cancel">
                    <template v-slot:custom-icon>
                        <Icon iconName="IconMedia" :iconSize="49" iconColor="#ECECEC" />
                    </template>

                    <template v-slot:custom-content>
                        <div class="form-content">
                            <div class="row top">
                                <div class="label">Media Type</div>
                                <div class="radio-holder">
                                    <div class="group" v-if="mediaType % 2 == 1">
                                        <input @change="filterMediaCategories" v-model="mediaType" v-bind:value="1"
                                            type="radio" value="1" name="media_type" id="video-archive" readonly />
                                        <label for="video-archive">Video Archive</label>
                                    </div>
                                    <div class="group" v-if="mediaType % 2 == 0">
                                        <input @change="filterMediaCategories" v-model="mediaType" v-bind:value="2"
                                            type="radio" value="2" name="media_type" id="audio-archive" readonly />
                                        <label for="audio-archive">Audio Archive</label>
                                    </div>
                                    <div class="group" v-if="mediaType % 2 == 1">
                                        <input @change="filterMediaCategories" v-model="mediaType" v-bind:value="3"
                                            type="radio" value="3" name="media_type" id="video-podcast" readonly />
                                        <label for="video-podcast">Video Podcast</label>
                                    </div>
                                    <div class="group" v-if="mediaType % 2 == 0">
                                        <input @change="filterMediaCategories" v-model="mediaType" v-bind:value="4"
                                            type="radio" value="4" name="media_type" id="audio-podcast" readonly />
                                        <label for="audio-podcast">Audio Podcast</label>
                                    </div>

                                </div>
                            </div>

                            <div class="row middle">
                                <div class="col left">
                                    <div v-if="mediaType == 3 || mediaType == 4" class="input-group">
                                        <label for="select-podcast" class="required">Select Podcast</label>
                                        <Dropdown :options="podcasts" v-on:selected="podcastId = $event.id ?? null"
                                        :initMedia="podcastId"    
                                        :searchable="true">
                                            <!-- :defaultValueById="podcastId" -->
                                        </Dropdown>
                                    </div>
                                    <FormInput inputType="text" labelText="Name" v-model="name" :showStar="true"
                                        style="margin-bottom: 20px" />
                                    <div class="input-holder">
                                        <div class="input-group">
                                            <label for="start-date">Start Date</label>
                                            <Datepicker input-class-name="datepicker-input" v-model="startDate"
                                                format="dd.MM.yyyy" :enable-time-picker="false" position="left" auto-apply
                                                :close-on-auto-apply="true" v-bind:clearable="false" />
                                        </div>

                                        <div class="input-group">
                                            <label for="end-date">End Date</label>
                                            <Datepicker input-class-name="datepicker-input" v-model="endDate"
                                                format="dd.MM.yyyy" :enable-time-picker="false" position="left" auto-apply
                                                :close-on-auto-apply="true" v-bind:clearable="false" />
                                        </div>
                                    </div>
                                    <div class="input-group">
                                        <label for="speaker">Speaker</label>
                                        <input v-model="speaker" type="text" id="speaker" />
                                    </div>
                                    <div class="input-group">
                                        <label for="description">Description</label>
                                        <textarea v-model="description" id="description"></textarea>
                                    </div>
                                </div>
                                <div v-if="mediaType == 1 || mediaType == 2" class="col right">
                                    <div class="media-category-group">
                                        <h3 class="title">Media Category / Subcategory</h3>
                                        <div class="category-holders">
                                            <NestedSubcategories :subcategories="mediaCategories"
                                                v-model="selectedCategories" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row bottom">
                                <div class="col left">
                                    <div class="input-group edit-media-file">
                                        <label for="media-file">Media File</label>
                                        <input type="file" id="media-file" :accept="acceptedFileTypes" @change="uploadVideo" />
                                    </div>

                                    <div v-if="uploadProgress && !isMediaUploadFinished" class="input-group">
                                        <label for="media-file">Uploading</label>
                                        <div id="progress_bar">
                                            <progress :value="uploadProgress" max="100"></progress>
                                        </div>
                                    </div>

                                    <div v-if="isMediaUploadFinished" class="input-group">
                                        <label for="video"></label>
                                        <div class="video-holder">
                                            <video id="video" ref="videoPlayer" :src="videoPlayerSource" controls></video>
                                        </div>
                                    </div>

                                    <div v-if="isMediaUploadFinished" class="input-group">
                                        <label for="start-point-progress">Start Point</label>
                                        <div class="start-point-holder">
                                            <input id="start-point-progress" type="range" ref="progressSlider"
                                                class="progress-slider" @input="seekVideo" min="0" step="0.01"
                                                :max="videoDuration" v-model="currentPosition" />
                                            <input id="start-point-input" type="text" v-model="currentPosition"
                                                @input="seekVideo" placeholder="Seconds" />
                                        </div>
                                    </div>

                                    <div v-if="isMediaUploadFinished" class="input-group duration-wrapper">
                                        <label for="video-duration">Video Duration</label>
                                        <input type="text" id="video-duration" v-model="videoDuration" :disabled="true" />
                                        <p>{{ `${(Math.floor(videoDuration * 100) / 100)} seconds` }}</p>

                                    </div>

                                    <div v-if="isMediaUploadFinished" class="featured-input">
                                        <label for="featired">Featured

                                            <input type="checkbox" id="featired" name="featired"
                                                @change="isFeaturedHandler">
                                            <font-awesome-icon :icon="['far', 'star']" v-if="!isFeatured" class="empty" />
                                            <font-awesome-icon :icon="['fas', 'star']" v-if="isFeatured" class="marked" />
                                        </label>
                                    </div>





                                    <div v-if="isMediaUploadFinished" class="switch-input-group">
                                        <div class="space"></div>
                                        <div class="description-switch-holder">
                                            <span>INACTIVE</span>

                                            <input v-model="isActive" type="checkbox" id="description-switch" hidden />
                                            <label for="description-switch"></label>

                                            <span>ACTIVE</span>
                                        </div>
                                    </div>

                                    <div v-if="isMediaUploadFinished && videoPlayer" class="download-file">
                                        <div class="space"></div>
                                        <a download :href="this.videoPlayer?.src"
                                            @click.prevent="downloadItem(videoCDNUrl)">
                                            download<font-awesome-icon :icon="['fas', 'download']" /></a>
                                    </div>

                                </div>

                                <div v-if="showMediaActions" class="col right">


                                    <!-- <ImageBox :path="'img/media/'" :imageType="2"
                                    :imagePath="posterImage"
                                    :cloudStorageInitData="cloudStorageInitData"
                                    :showGetFrameImageButton="videoPath"
                                    @on-change="setFeaturedPosterImage()"
                                    @on-take-from-frame="setFeaturedPosterImage" imageTitle="Poster Image"
                                    imageInfo="Recommended dimensions: 600x800 as a 3x4 or 450x800 as a 9x16 or 400x800 as a 9x18" /> -->

                                    <div class="media-group">
                                        <label for="category-image">
                                            <img v-if="posterImage" :src="posterImage" alt="" />
                                            <div v-else>
                                                <div class="line"></div>
                                                <div class="line"></div>
                                                <div class="circle">
                                                    <font-awesome-icon :icon="['fas', 'plus']" />
                                                </div>
                                            </div>
                                        </label>
                                        <input @change="handlePosterImageChange" id="category-image" type="file"
                                            accept="png" hidden />
                                        <div v-if="!isVideoExternal" class="button-holder">
                                            <button type="button" @click="setPosterImage()">
                                                Get from frame
                                            </button>
                                        </div>
                                        <div class="text-holder">
                                            <span>Poster Image</span>
                                            <p>
                                                Help info... <br />
                                                Recommended dimensions: 600x800 as a 3x4 or 450x800 as a
                                                9x16 or 400x800 as a 9x18
                                            </p>
                                        </div>
                                    </div>

                                    <div class="media-group media-group-two ">
                                        <label for="media-image">
                                            <img v-if="featuredPosterImage" :src="featuredPosterImage" alt="" />
                                            <div v-else>
                                                <div class="line"></div>
                                                <div class="line"></div>
                                                <div class="circle">
                                                    <font-awesome-icon :icon="['fas', 'plus']" />
                                                </div>
                                            </div>
                                        </label>
                                        <input @change="handleFeaturedPosterImageChange" id="media-image" type="file"
                                            accept="png" hidden />
                                        <div v-if="!isVideoExternal" class="button-holder">
                                            <button type="button" @click="setFeaturedPosterImage()">
                                                Get from frame
                                            </button>
                                        </div>
                                        <div class="text-holder">
                                            <span>Mobile Featured Image</span>
                                            <p>
                                                Help info... <br />
                                                Recommended dimensions: 600x800 as a 3x4 or 450x800 as a
                                                9x16 or 400x800 as a 9x18
                                            </p>
                                        </div>
                                    </div>

                                   
                                    
                                </div>
                            </div>
                        </div>
                    </template>
                </PageView>
            </form>
        </div>
    </div>
</template>

<script scoped>
import { ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import axios from "../js/axios.js";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useCloudStorage } from '@/composables/useCloudStorage.js';

import PageView from "../components/shared/PageView.vue";
import NestedSubcategories from "../components/NestedCategoryCheckbox.vue";
import Dropdown from "../components/SelectDropdown.vue";
import FormInput from "../components/shared/FormInput.vue";
import ImageBox from "../components/shared/ImageBox.vue";

import Icon from "../components/Icon.vue";

export default {
    components: {
        PageView,
        Icon,
        Datepicker,
        NestedSubcategories,
        Dropdown,
        FormInput,
        ImageBox,
    },
    data() {
        return {
            inputAdditionalStyles: {
                width: "500px",
            },
            labelAdditionalStyles: {
                // color:'#000',
                // fontSize:'15px',
                width: "180px",
            },

        };
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const { fetchCloudStorageData, uploadFile, s3Config } = useCloudStorage();

        const mediaId = ref(router.currentRoute.value.params.id);
        const userRole = ref(store.state.user.role);
        const id = ref(store.state.user.organization.id)
        const isVideoExternal = ref(true);

        const organizationId = ref(null);

        const podcastId = ref(null);
        const podcasts = ref([]);

        const mediaPath = ref(null);
        const mediaType = ref(null);
        const name = ref("");
        const startDate = ref(null);
        const endDate = ref(null);
        const speaker = ref("");
        const description = ref("");
        const selectedCategories = ref([]);
        const isActive = ref(false);

        const posterImage = ref(null);
        const featuredPosterImage = ref(null);
        const posterImagePath = ref(null);
        const featuredPosterImagePath = ref(null);

        const mediaCategories = ref([]);

        const isMediaUploadFinished = ref(false);
        const showMediaActions = ref(true);

        const uploadProgress = ref(0);

        const videoPath = ref(null);

        const currentPosition = ref(0);
        const videoDuration = ref(0);
        const videoThumbnail = ref(0);

        const videoPlayer = ref(null);
        const videoPlayerSource = ref(null);
        const progressSlider = ref(null);
        const isFeatured = ref(null);
        const podcastsType = ref(null);
        const videoCDNUrl = computed(() => {
            return videoPlayer.value.src;
        });


        const downloadItem = (url, label) => {
            axios.get(url, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data], { type: response.headers['content-type'] });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = label || url.split('/').pop(); // Use filename from URL if label is not provided
                    document.body.appendChild(link); // Append the link to the DOM
                    link.click();
                    document.body.removeChild(link); // Remove the link from the DOM after downloading
                    URL.revokeObjectURL(link.href);
                })
                .catch(error => {
                    console.error('Error downloading file:', error);
                });
        };

        const handlePosterImageChange = async (event) => {
            const file = event.target.files[0];

            await setPosterImage(file);
        };

        const handleFeaturedPosterImageChange = async (event) => {
            const file = event.target.files[0];

            await setFeaturedPosterImage(file);
        };

        const isFeaturedHandler = () => {
            isFeatured.value = !isFeatured.value
        }

        const setCloudStorageUploadData = () => {
            videoPath.value = s3Config.value?.path;
        }

        const fetchCloudStorageInitData = async (organizationId = null) => {
            await fetchCloudStorageData(organizationId ?? 0);
            setCloudStorageUploadData();
        };

        onMounted(async () => {
            await fetchMedia();

            // Fetch Media Categories
            if (userRole.value == 2 || userRole.value == 3) {
                await fetchMediaCategories();
                await fetchCloudStorageInitData();
            } else {
                await fetchMediaCategories(organizationId.value);
                await fetchCloudStorageInitData(organizationId.value);
            }
            await fetchPodcasts();
        });

        // Fetch Media
        const fetchMedia = async () => {
            await axios
                .get(`/v1/media/${mediaId.value}`)
                .then(async (response) => {
                    if (response.data.status === "success") {
                        organizationId.value = response.data.data.media?.organization_id;
                        name.value = response.data.data.media?.name;
                        startDate.value =
                            response.data.data.media.additional_data?.start_date;
                        endDate.value = response.data.data.media.additional_data?.end_date;
                        speaker.value = response.data.data.media.additional_data?.speaker;
                        description.value = response.data.data.media?.description;
                        selectedCategories.value =
                            response.data.data.media?.selected_categories ?? []; // ?
                        mediaType.value = response.data.data.media?.type;
                        mediaPath.value = response.data.data.media?.path;
                        podcastsType.value = response.data.data.media?.type % 2 == 1 ? 1 : 2;
                        currentPosition.value =
                            response.data.data.media.additional_data?.start_point;
                        videoDuration.value =
                            response.data.data.media.additional_data?.duration;

                        isMediaUploadFinished.value = response.data.data.media?.path
                            ? true
                            : false;

                        posterImage.value = response.data.data.media.additional_data?.image
                            ? import.meta.env.VITE_CDN_BASE_URL +
                            response.data.data.media.additional_data?.image
                            : null;
                        featuredPosterImage.value = response.data.data.media.additional_data
                            ?.mobile_image
                            ? import.meta.env.VITE_CDN_BASE_URL +
                            response.data.data.media.additional_data?.mobile_image
                            : null;

                        posterImagePath.value =
                            response.data.data.media.additional_data?.image;
                        featuredPosterImagePath.value =
                            response.data.data.media.additional_data?.mobile_image;

                        videoPath.value = response.data.data.media?.path;
                        videoPlayerSource.value =
                            import.meta.env.VITE_CDN_BASE_URL + response.data.data.media?.path;
                        isActive.value = response.data.data.media?.is_active ? true : false;

                        podcastId.value =
                            response.data.data.media.additional_data?.podcast_id ?? null;
                        isFeatured.value = response.data.data.media?.additional_data?.is_featured_video || false;
                    } else {
                        store.dispatch("snack/error", "An error occured");
                    }
                })
                .catch((error) => { });
        };

        // Save Media
        const updateMedia = async (quiet = false) => {
            await axios
                .patch(`/v1/media/${mediaId.value}`, {
                    type: mediaType.value,
                    name: name.value,
                    path: mediaPath.value,
                    description: description.value,
                    additional_data: {
                        start_date: startDate.value,
                        end_date: endDate.value,
                        speaker: speaker.value,
                        start_point: currentPosition.value,
                        duration: videoDuration.value,
                        image: posterImagePath.value,
                        mobile_image: featuredPosterImagePath.value,
                        podcast_id: podcastId.value,
                        is_featured_video: isFeatured.value,
                    },
                    selected_categories: selectedCategories.value,
                    is_active: isActive.value ? 1 : 0,
                })
                .then(async (response) => {
                    if (response.data.status === "success") {
                        if (!quiet) {
                            store.dispatch("snack/success", "Media successfully updated");
                            router.push({ name: "media" });
                        }
                    } else {
                        store.dispatch("snack/error", "An error occured");
                    }
                })
                .catch((error) => {
                    console.log(error);

                    if (error.response.status === 401) {
                        store.dispatch(
                            "snack/warning",
                            error.response.data.message ?? null
                        );
                    } else if (error.response.status === 403) {
                        store.dispatch(
                            "snack/error",
                            "You don't have permissions for this action"
                        );
                    } else if (error.response.status === 422) {
                        store.dispatch(
                            "snack/warning",
                            Object.values(error.response.data.data)[0]?.[0] ??
                            error.response.data.message ??
                            null
                        );
                    } else {
                        store.dispatch("snack/error", "An error occured");
                    }
                });
        };

        const acceptedFileTypes = computed(() => {
            if (mediaType.value === 1 || mediaType.value === 3) {
                return 'video/*'; // For Video Archive and Video Podcast
            } else if (mediaType.value === 2 || mediaType.value === 4) {
                return 'audio/*'; // For Audio Archive and Audio Podcast
            }
            return ''; // Default case (no restriction)
        });

        // Upload Video
        const uploadVideo = async (event) => {
            if (!event.target.files || event.target.files.length === 0) {
                return;
            }

            isMediaUploadFinished.value = false;

            const file = event.target.files[0];

            const isVideoType = mediaType.value === 1 || mediaType.value === 3;
            const isAudioType = mediaType.value === 2 || mediaType.value === 4;

            // Check if the file type matches the selected media type
            if ((isVideoType && !file.type.startsWith('video/')) || (isAudioType && !file.type.startsWith('audio/'))) {
                event.target.value = "";
                store.dispatch('snack/error', "Invalid file type. Please select a valid media file.");
                return;
            }

            const blobName = await getMediaBlob(videoPath.value, file);
            mediaPath.value = blobName;
            const promises = [];

            try {
                promises.push(uploadFile(file, blobName, progressHandler));
                
                await Promise.all(promises);
            } catch (error) {
                store.dispatch("snack/error", "Media save failed");

                return;
            }

            isMediaUploadFinished.value = true;
            const videoURL = URL.createObjectURL(file);
            videoPlayerSource.value = videoURL;
            isVideoExternal.value = false;

            await updateMedia(true);
        };

        watch(videoPlayer, (value) => {
            if (value) {
                videoPlayer.value.addEventListener("loadedmetadata", () => {
                    videoDuration.value = videoPlayer.value.duration;
                });
            }
        });

        const getMediaBlob = async (path, file) => {
            const fileNameBase = file.name.match(/^.*?([^\\/.]*)[^\\/]*$/)[1];
            const fileName = fileNameBase.replace(/[ &\/\\#,+()$~%.'":*?<>{}]/g, "-");
            const filenameWithExtension = file.name.split(".").pop();

            const KeyFileName = `${fileName}-${Date.now()}.${filenameWithExtension}`;

            return path + KeyFileName;
        };

        const progressHandler = (uploadPercent, progress) => {
            uploadProgress.value = uploadPercent;
        };

        function initializeSubcategories(subcategories) {
            if (!subcategories || subcategories.length === 0) {
                return;
            }

            for (const category of subcategories) {
                category.showSubcategories = true;

                if (category.subcategories && category.subcategories.length > 0) {
                    initializeSubcategories(category.subcategories);
                }
            }
        }

        // Fetch Media Categories
        const fetchMediaCategories = async (id) => {
            const url = id
                ? `/v1/organizations/${id}/media/categories`
                : `/v1/media/categories`;
            const dataToSend = {
                type: mediaType.value % 2 == 1 ? 1 : 2
            };

            await axios
                .get(url, {
                    params: dataToSend,
                })
                .then(async (response) => {
                    if (response.data.status === "success") {
                        let subcategories = response.data.data.media_categories;
                        initializeSubcategories(subcategories);

                        mediaCategories.value = subcategories;
                    } else {
                        store.dispatch("snack/error", "An error occured");
                    }
                })
                .catch((error) => { });
        };

        // Start Point
        const seekVideo = () => {
            videoPlayer.value.currentTime = currentPosition.value;
        };

        // Set Poster images from the video current frame
        const getFrameImageFile = (video) => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            return new Promise((resolve, reject) => {
                
                canvas.toBlob((blob) => {
                    if (blob) {
                        const file = new File([blob], "captured_frame.png");
                        resolve(file);
                    } else {
                        reject(new Error("Failed to capture frame."));
                    }
                }, "image/png");
            });
        };

        const setPosterImage = async (imageFile = null) => {
        
            const file = imageFile ?? (await getFrameImageFile(videoPlayer.value));
            const blobName = await getMediaBlob("img/media/", file);
            const promises = [];

            try {
                promises.push(
                    uploadFile(file, blobName, progressHandler)
                );

                await Promise.all(promises);
            } catch (error) {
                store.dispatch("snack/error", "Failed uploading Poster image");

                return;
            }

            posterImagePath.value = blobName;
            posterImage.value = URL.createObjectURL(file);

            await updateMedia(true);
        };

        const setFeaturedPosterImage = async (imageFile = null) => {
            const file = imageFile ?? (await getFrameImageFile(videoPlayer.value));
            const blobName = await getMediaBlob("img/media/", file);
            const promises = [];

            try {
                promises.push(
                    uploadFile(file, blobName, progressHandler)
                );

                await Promise.all(promises);
            } catch (error) {
                store.dispatch("snack/error", "Failed uploading Poster image");

                return;
            }

            featuredPosterImagePath.value = blobName;
            featuredPosterImage.value = URL.createObjectURL(file);

            await updateMedia(true);
        };

        // Fetch Podcasts
        const fetchPodcasts = async () => {
            let data = {
                type: podcastsType.value,
                organization_id: organizationId.value
            }
            await axios
                .get("/v1/podcasts/list", {
                    params: data
                })
                .then(async (response) => {
                    podcasts.value = response.data.data;
                })
                .catch((error) => { });
        };

        const filterMediaCategories = async () => {

            console.log(7777);
            
            // if (organizationId.value && userRole.value == 1) {
            selectedCategories.value = [];
            await fetchMediaCategories(organizationId.value);
            podcastsType.value = mediaType.value % 2 == 1 ? 1 : 2;
            await fetchPodcasts();
            // }
        };

        const cancel = () => {
            router.back();
        };

        return {
            showMediaActions,
            selectedCategories,
            name,
            mediaType,
            startDate,
            endDate,
            speaker,
            description,
            isActive,
            posterImage,
            featuredPosterImage,
            handlePosterImageChange,
            handleFeaturedPosterImageChange,
            uploadVideo,
            uploadProgress,
            mediaCategories,
            currentPosition,
            videoDuration,
            videoPlayer,
            progressSlider,
            seekVideo,
            isMediaUploadFinished,
            videoPlayerSource,
            videoThumbnail,
            updateMedia,
            setPosterImage,
            setFeaturedPosterImage,
            isVideoExternal,
            cancel,
            podcasts,
            podcastId,
            filterMediaCategories,
            isFeatured,
            isFeaturedHandler,
            podcastsType,
            organizationId,
            downloadItem,
            videoCDNUrl,
            videoPath,
            acceptedFileTypes
        };
    },
};
</script>

<style>
/* .col.right{
  min-height: 300px;
} */

.category-holders {
    height: 100%;
    max-height: 300px !important;
    overflow: auto;
}

/* Switch Button */
#media-edit .switch-input-group {
    display: flex;
    align-items: center;
    margin-top: 50px;
}

#media-edit .switch-input-group .space,
#media-edit .download-file .space {
    width: 184px;
    height: 10px;
}

.download-file {
    display: flex;
    margin-top: 20px;
    text-transform: capitalize;
}

.download-file>a {
    color: #000;
    text-decoration: none;
    font-size: 14px;
    display: block;
    align-items: center;
    transition: 0.5s;
}

.download-file>a:hover {
    scale: 1.05;
}

.download-file>a>svg {
    color: #000;
    font-size: 17px;
    margin-left: 8px;
}

#media-edit .description-switch-holder {
    display: flex;
    align-items: center;
}

#media-edit .description-switch-holder {
    /* color: #C8CB18; */
    text-transform: uppercase;
    font-size: 15px;
}

#media-edit .description-switch-holder {
    text-transform: uppercase;
    font-size: 15px;
}

#media-edit .description-switch-holder input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
}

#media-edit .description-switch-holder label {
    cursor: pointer;
    text-indent: -9999px;
    width: 42px;
    height: 26px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
    margin: 0px 10px;
}

#media-edit .description-switch-holder label:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.1s;
}

#media-edit>div>form>div>div.content>div>div.row.middle>div.col.left>div.formInput>input[type=text] {
    width: 80%;
}

#media-edit .description-switch-holder input:checked+label {
    background: #c8cb18;
}

#media-edit .description-switch-holder input:checked+label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

#media-edit .description-switch-holder label:active:after {
    width: 20px;
}

.category-holders {
    max-height: 200px;
    overflow: auto;
}

.category-holders>ul {
    margin-left: 0px;
}

.datepicker-input {
    width: 100%;
    min-width: 145px !important;
    max-width: 165px !important;
    padding: 8px 12px 8px 35px !important;
    font-size: 15px;
    line-height: 15px;
}

.dp__main {
    width: unset;
}

#media-edit {
    display: flex;
    flex-direction: column;
}

/* Table */
#media-edit .table-holder {
    min-height: 650px;
    border-radius: 4px;
    margin-top: 40px;
    /* overflow: hidden; */
    border: 0.5px solid #d0d0d0;
    padding: 10px 20px;
    box-shadow: 0px 0px 6px #0000000a;
    background: #fff;
}


#media-edit .table-holder form {
    display: flex;
    min-height: 550px;
    flex-direction: column;
    justify-content: space-between;
}

#media-edit .table-holder form .row {
    display: flex;
    justify-content: flex-start;
}

/* Left Col */
#media-edit .table-holder form .row .col.left {
    padding-right: 4rem;
    width: 65%;
}

/* Top */
#media-edit .row.top {
    margin-bottom: 26px;
    color: #464545;
}

#media-edit .row.top div.label {
    min-width: 170px;
    text-align: right;
    margin-right: 14px;
    font-size: 13px;
    color: #464545;
}

#media-edit .row.top .radio-holder {
    display: flex;
    flex-wrap: wrap;
}

#media-edit .row.top .radio-holder .group {
    display: flex;
    align-items: center;
    min-width: 170px;
}

#media-edit .row.top .radio-holder .group input {
    margin-right: 8px;
}

#media-edit .input-group {
    display: flex;
    margin-bottom: 14px;
    align-items: center;
}

#media-edit .input-group.size div {
    display: flex;
    align-items: center;
}

#media-edit .input-group.size input {
    min-width: 160px;
    width: 170px;
}

#media-edit .input-group.size span {
    margin: 0px 20px;
}

#media-edit .input-group label {
    position: relative;
    min-width: 170px;
    text-align: right;
    margin-right: 14px;
    font-size: 13px;
    color: #464545;
}

#media-edit .input-group label.required::after {
    content: "*";
    margin-left: 4px;
    color: #ef2d2d;
}

#media-edit .input-group textarea,
#media-edit .input-group input {
    width: 100%;
    min-width: 384px;
    padding: 8px 12px;
    outline: none;
    border-radius: 4px;
    box-shadow: 0px 0px 6px #0000000a;
    border: 0.5px solid #ececec;
    font-size: 13px;
}

#media-edit .input-group input::placeholder {
    color: #b1b1b1;
}

#media-edit .input-group textarea {
    resize: vertical;
    height: 80px;
}

#media-edit .input-group textarea:hover,
#media-edit .input-group textarea:focus,
#media-edit .input-group input:hover,
#media-edit .input-group input:focus {
    box-shadow: 0px 0px 6px #88d3f394;
    border: 0.30000001192092896px solid #a9ddf3;
}

/* #media-edit .input-holder {
    display: flex;
}

#media-edit .input-holder .input-group:last-child {
    width: 100%;
}

#media-edit .input-holder .input-group:last-child label {
    width: 100%;
    min-width: 80px;
} */

/* Right Col */
#media-edit .table-holder form .row .col.right {
    width: 35%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#media-edit .col.right .title {
    font-size: 15px;
    color: #464545;
    margin-bottom: 20px;
}

#media-edit .col.right .media-group {
    width: 250px;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

#media-edit .col.right .media-group label {
    width: 100%;
    height: 160px;
    border-radius: 8px;
    /* overflow: hidden; */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    cursor: pointer;
    margin: 0 auto;
}

#media-edit .col.right .media-group label img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#media-edit .col.right .media-group label div {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #f9f9f9;
}

#media-edit .col.right .media-group label div .line {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 1.5px;
    background-color: #d0d0d0;
    margin: auto;
}

#media-edit .col.right .media-group label div .line:nth-child(1) {
    transform: rotate(30deg);
    z-index: 1;
}

#media-edit .col.right .media-group label div .line:nth-child(2) {
    transform: rotate(-30deg);
    z-index: 2;
}

#media-edit .col.right .media-group label div .circle {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    border-radius: 100%;
    border: 1px solid #d0d0d0;
    color: #d0d0d0;
    font-weight: bold;
    font-size: 24px;
    background-color: #ffffff;
    z-index: 3;
}

.info-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-holder{
    /* width: 100% !important; */
    background: transparent !important;

}

.col.right:deep(.button-holder-3>button){
    background:  #000 !important;
}

#media-edit .col.right .media-group .text-holder span {
    display: block;
    font-size: 1rem;
    margin-top: 20px;
    margin-bottom: 1rem;
    text-align: center;
}

#media-edit .col.right .media-group .text-holder p {
    font-size: 10px;
    color: #666666;
    text-align: center;
}

#media-edit .row.middle {
    margin-bottom: 26px;
}

#media-edit #media-file:hover,
#media-edit #media-file:focus {
    box-shadow: 0px 0px 6px #0000000a;
    border: 0.5px solid #ececec;
}

#media-edit .row.save-current-state {
    display: flex;
    justify-content: center;
    margin-bottom: 26px;
}

#media-edit .row.save-current-state button,
#media-edit .media-group button {
    width: 200px;
    color: #464545;
    text-decoration: none;
    border-radius: 6px;
    font-size: 15px;
    padding: 8px 0px;
    outline: none;
    border: none;
    cursor: pointer;
}

#media-edit .media-group .button-holder {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

#media-edit .media-group button {
    width: 100%;
}

.media-group-two img {
    width: 130px !important;
}

.media-group-two {
    width: 130px !important;
}

#media-edit .row.bottom .video-holder {
    width: 100%;
    min-width: 384px;
    max-height: 500px;
    display: flex;
    justify-content: center;
}

#media-edit .row.bottom video {
    width: 100%;
    height: auto;
}

#media-edit .start-point-holder {
    width: 100%;
    display: flex;
    align-items: center;
}

#media-edit .start-point-holder #start-point-progress {
    min-width: 0px;
    border: none;
    box-shadow: none;
    padding: 0px;
    margin-right: 10px;
}

#media-edit .start-point-holder #start-point-input {
    min-width: 0px;
    max-width: 100px !important;
}

#media-edit .start-point-holder span {
    width: 70px;
    text-align: right;
}

#media-edit #start-point:hover {
    box-shadow: none;
}

#media-edit #video-duration {
    cursor: not-allowed;
}

.edit-media-file {
    min-width: 360px;
    display: flex;
    align-items: center;
    margin: 15px 0;
    /* margin-left: 11%; */
    font-size: 14px;
}

.edit-media-file>input {
    /* margin-left: 15px; */
    background: white;
    border: 1px solid lightgray;
    padding: 5px;
    border-radius: 5px;
    color: #808080;
    cursor: pointer;
}

.edit-media-file>input::file-selector-button {
    background: white;
    border: none;
    border-right: 1px solid #d3d3d3;
    padding: 5px 10px;
    cursor: pointer;
    color: #808080;

}

.duration-wrapper {
    position: relative;
    user-select: none;
}

.duration-wrapper p {
    position: absolute;
    left: 200px;
}

.duration-wrapper>input {
    color: transparent;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    pointer-events: none;
}

.featured-input {
    display: flex;
    align-items: center;
}

.featured-input>label {
    min-width: 215px;
    text-align: right;
    margin-right: 14px;
}

.featured-input>label>input {
    display: none;
}

.featured-input>label>svg {
    font-size: 20px;
    margin-left: 15px;
    cursor: pointer;
}

.featured-input>label>.empty {
    color: #b1b1b1;
}

.featured-input>label>.marked {
    color: #ffe70f;
}

@media screen and (min-width: 800px) {
    #media-edit .table-holder form .row .col.left {
        min-width: 700px;
    }
}

@media screen and (max-width: 1400px) {
    #media-edit .row.bottom {
        flex-direction: column-reverse !important;
    }

    #media-edit .row.middle {
        flex-direction: column;
    }

    #media-edit .table-holder form .row .col.right {
        align-items: unset;
        padding-right: 4rem;
        min-width: 700px;
        width: 60%;
    }

    #media-edit .table-holder form .row.bottom .col.right {
        margin-top: 20px;
        padding-left: 190px;
    }

    #media-edit .table-holder form .row .col.right .media-category-group {
        display: flex;
    }

    #media-edit .table-holder form .row .col.right .media-category-group h3 {
        width: 100%;
        max-width: 170px;
        text-align: right;
        margin-right: 14px;
    }

    #media-edit .category-holders {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    #media-edit .table-holder form .row {
        flex-direction: column;
    }

    #media-edit .row.top div.label {
        text-align: left;
        margin-bottom: 4px;
    }

    #media-edit .row.top .radio-holder .group {
        width: 100%;
        min-width: unset;
    }

    #media-edit .table-holder form .row .col.left {
        width: 100%;
        padding-right: 0;
    }

    #media-edit .input-group,
    #media-edit .table-holder form .row .col.right .media-category-group {
        width: 100%;
        flex-direction: column;
    }

    #media-edit .input-group label,
    #media-edit .table-holder form .row .col.right .media-category-group h3 {
        text-align: left;
        margin-bottom: 4px;
    }

    #media-edit .input-group textarea,
    #media-edit .input-group select,
    #media-edit .input-group input {
        min-width: 120px !important;
        max-width: unset !important;
        width: 100% !important;
    }

    #media-edit .input-holder {
        flex-direction: column;
    }

    #media-edit .row.save-current-state {
        flex-direction: unset !important;
    }

    #media-edit .table-holder form .row.bottom .col.right {
        margin-top: 20px;
        padding-left: 0px;
    }

    #media-edit .row.bottom .video-holder {
        min-width: 240px;
    }

    #media-edit .table-holder form .row .col.right .media-category-group h3 {
        max-width: unset;
    }

    #media-edit .table-holder form .row .col.right {
        min-width: 300px;
    }
}
</style>
